/*****************************
  Action box
*****************************/

.callout { 
	padding: 50px 0px 50px 50px;
	.callout-title { 
		align-self: center;  
    }	
}

@media (max-width:767px) {
	.callout { 
		padding: 30px;	
	}
}
