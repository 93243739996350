/*****************************
  List
*****************************/

  ul.list {
    padding: 0;
    margin: 0;
    li{
	    list-style: none;
	    margin-bottom: 15px;
      display: flex;
	    i{
        color: $primary;
        margin: 5px 10px 0px 0px;
	    }
     	&:last-child {
 	    margin-bottom: 0;
      }
    }
  }

ul.list-default {
    padding: 0;
    margin: 0;

    li {
         margin-left: 18px;
         margin-bottom: 5px;
        &:last-child {
        margin-bottom: 0;
         }
       }
}

  ol.list {
      padding: 0;
      margin: 0;

        li {
         margin-left: 18px;
         margin-bottom: 5px;
        &:last-child {
        margin-bottom: 0;
         }
       }

  }