/*****************************
  Blog post
*****************************/

.blog-post-style-1 {
	.blog-post {
		margin-bottom: 0;
	}
	.blog-post-img {
		position: relative;
		&:before {
			content: "";
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			background: linear-gradient(to bottom, rgba($gray-9, 0) 0, rgba($gray-9, 0.9) 100%);
			height: 75%;
			transition: all 0.3s ease-in-out;
		}
	}
	.blog-post .blog-post-info {
		display: block;
		position: absolute;
		bottom: 0;
		padding: 20px;
		margin: 0;
		> a {
			color: $white;			
			&:hover {
				color: $primary;
			}
		}
		.blog-post-title {
			line-height: 26px;
			a {
				font-size: 22px;
				margin: 3px 0px 0px;
				line-height: 34px;
				font-weight: 700;
				color: $white;
				line-height: 24px;
				&:hover {
					color: $primary;
				}
			}
		}
	}
}