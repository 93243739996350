/*****************************
  Case Study
*****************************/

	.case-study {
		.case-study-item {
			.case-study-category {
				display: inline-block;
				margin-bottom: 5px;
				font-size: 13px;
				font-weight: 700;

				a {
					&:hover {
						color: $gray-8;
					}

				}

			}

			.case-study-img {
				position: relative;

				&:before {
					content: "";
					background: rgba($gray-9, 0.8);
					position: absolute;
					left: 0;
					top: 0;
					width: 100%;
					height: 100%;
					opacity: 0;
					border-radius: $border-radius-md;
					transition: all 0.3s ease-in-out;
				}

				img {
					border-radius: $border-radius-md;
				}

				a {
					background-color: $primary;
					height: 50px;
					width: 50px;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 16px;
					color: $white;
					position: absolute;
					left: 50%;
					top: 20%;
					opacity: 0;
					border-radius: $border-radius-md;
					transform: translateX(-50%) translateY(-50%);

					&:hover {
						background-color: $white;
						color: $primary;
					}

				}

			}

			&:hover {
				.case-study-img {
					&:before {
						opacity: 1;
					}

					a {
						top: 50%;
						opacity: 1;
					}

				}

			}

			.case-study-info {
				padding: 78px 30px 30px 30px;
				margin-top: -50px;
				border: 2px solid $border-color;
				border-radius: $border-radius-md;

				.case-study-title {
					margin-bottom: 0;

					a {
						&:hover {
							color: $primary;
						}

					}

				}

			}

		}

	}