/*****************************
    Team
*****************************/

  /* Team Style 1 */
  .team.team-style-1 {
      .team-img {
          position: relative;

          &:before {
              content: "";
              background: rgba($gray-9, 0.8);
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              opacity: 0;
              border-radius: $border-radius-md;
              transition: all 0.3s ease-in-out;
          }

          img {
              border-radius: $border-radius-md;
          }

          a {
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 14px;
              padding: 10px 20px;
              position: absolute;
              left: 50%;
              top: 20%;
              opacity: 0;
              border-radius: $border-radius-md;
              transform: translateX(-50%) translateY(-50%);
          }

      }

      &:hover {
          .team-img {
              &:before {
                  opacity: 1;
              }

              a {
                  top: 50%;
                  opacity: 1;
              }

          }

      }

      .team-info {
          padding: 72px 30px 30px 30px;
          margin-top: -44px;
          border: 2px solid $border-color;
          border-radius: $border-radius-md;

          .team-info-title {
              a {
                  &:hover {
                      color: $primary;
                  }

              }

          }

      }

  }

  /* Team Style 2 */
  .team.team-style-2 {
      border-radius: $border-radius-md;

      .team-img {
          img {
              border-radius: $border-radius-md;
          }

      }

      .team-info {
          padding: 20px;
          position: relative;
          transition: all 0.3s ease-in-out;
          background: $white;
          border: 2px solid $border-color;
          border-radius: $border-radius-md;
          margin-top: -20px;

          .btn-link {
              font-size: 12px;
              font-weight: 700;
              position: absolute;
              bottom: 20px;
              left: 20px;
              opacity: 0;
              visibility: hidden;
              transition: all 0.3s ease-in-out;
          }

      }

      &:hover {
          .team-info {
              margin-top: -60px;
              padding-bottom: 60px;

              .btn-link {
                  opacity: 1;
                  visibility: visible;
              }

          }

      }

  }